<template>
  <section>
    <header class="mb-2">
      <b-card>
        <b-row>
          <b-col md="2" sm="12">
            <p><strong>Judul</strong></p>
            <p>{{ pb.judul }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Tanggal</strong></p>
            <p>{{ humanDate(pb.tanggal) }}</p>
          </b-col>
          <b-col md="4" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ pb.keterangan ? pb.keterangan : "-" }}</p>
          </b-col>
          <b-col md="2" sm="12">
            <p><strong>Total</strong></p>
            <p>Rp. {{ pb.total ? formatRupiah(pb.total) : "-" }}</p>
          </b-col>
          <b-col md="2" sm="12" >
            <p><strong>Status Finance</strong></p>
            <p><b-badge v-if="pb.id_akun == null || pb.id_akun == 0" variant="light-danger">Belum Pilih Akun</b-badge></p>
            <p><b-badge v-if="pb.id_akun > 0" variant="light-success">Sudah Pilih Akun</b-badge></p>
          </b-col>
          
          <b-col md="3" sm="12">
            <b-button :disabled="items.length < 1" v-if="isFinance && pb.selesai == 0 && (pb.id_akun == null || pb.id_akun == 0)" size="sm" variant="info" @click="confirmdiberikan()"
              >Pilih Sumber Pengeluaran</b-button
            >
            <b-button v-if="isFinance && pb.selesai == 1 && pb.id_akun > 0" size="sm" variant="outline-danger" @click="confirmcanceljurnal()"
              >Cancel Akun</b-button>
          </b-col>
          <b-modal
            id="modal-select2"
            v-model="showModalapprfinance"
            title="Pilih Akun dan Kas untuk Biaya Pajak"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
          >
            <b-form>
              <b-form-group label="Nominal Biaya">
                <h4>
                  <i class="text-success">
                    <strong>Rp {{ formatRupiah(pb.total) }}</strong>
                  </i>
                </h4>
              </b-form-group>
              <b-form-group label="Pilih Akun" label-for="vue-select-akun">
                <b-form-select id="vue-select-akun" v-model="form2.id_akun" :options="id_akun" class="mb-1" />
              </b-form-group>
              <b-form-group label="Pilih Kas" label-for="vue-select">
                <b-form-select id="vue-select" v-model="form2.id_kas" :options="id_kas" class="mb-1" />
                <i class="text-danger" v-if="invalidKas">Saldo Kas kurang dari nominal biaya</i>
              </b-form-group>
            </b-form>

            <template #modal-footer>
              <b-button
                :disabled="!isValidAkunForm"
                type="submit"
                @click.prevent="diberikan"
                variant="primary"
                class="mr-1"
              >
                Simpan
              </b-button>
            </template>
          </b-modal>
        </b-row>
      </b-card>
    </header>
    <main>
      <b-card action-collapse title="Pajak">
        <b-overlay :show="loading">
          <b-row>
            <b-col
              class="my-1"
              >
              <!-- v-if="pb.diterima == null && isAdminGudang && pb.selesai != 1" -->
              <b-button v-if="pb.selesai == 0" v-b-modal.modal-tambah variant="primary" @click="add()">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Rincian Ajuan
              </b-button>
              <b-modal
                v-model="showModal"
                id="modal-"
                ok-title="Tutup"
                ok-variant="secondary"
                ok-only
                centered
                title="Form "
              >
                <validation-observer ref="formbiaya_pajak">
                  <b-form>
                    <b-row>
                      <!-- item -->
                      <b-col cols="12">
                        <b-form-group label="Item Pajak" label-for="v-item">
                          <b-form-input
                            v-model="form.item"
                            id="v-item"
                            placeholder="Ex: PPN"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- qty -->
                      <b-col cols="12">
                        <b-form-group label="Kuantitas" label-for="v-qty">
                          <b-form-input
                            v-model="form.qty"
                            id="v-qty"
                            type="number"
                            placeholder="Isi kuantitas"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- biaya -->
                      <b-col cols="12">
                        <b-form-group label="Biaya" label-for="v-biaya">
                          <b-input-group
                            prepend="Rp."
                            class="input-group-merge"
                          >
                          <b-form-input 
                          v-model="form.biaya"
                              class="form-control"
                              @keyup="doFormatRupiah"
                          />

                            <!-- <cleave
                              id="number"
                              v-model="form.biaya"
                              class="form-control"
                              :raw="false"
                              :options="options.number"
                              placeholder="10,000"
                            /> -->
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- submit and reset -->
                      <b-col cols="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          @click.prevent="submit"
                          variant="primary"
                          class="mr-1"
                        >
                          Simpan
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          type="reset"
                          variant="outline-secondary"
                        >
                          Reset
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              >
              <!-- v-if="(pb.diterima == null || pb.diberikan == null) && pb.selesai == 0" -->
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                
                <template #cell(biaya)="{ item }">
                  Rp. {{ formatRupiah(item.biaya) }}
                </template>
                <template #cell(jumlah)="{ item }">
                  Rp. {{ formatRupiah(item.qty * item.biaya) }}
                </template>

                <template
                  #cell(actions)="row"
                  >
                  <!-- v-if="pb.diterima == null && pb.selesai == 0" -->
                  <!-- v-if="allowUpdate()" -->
                  <b-button
                    v-if="pb.diterima == null && pb.selesai == 0"
                    title="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- v-if="allowDelete()" -->
                  <!-- v-if="pb.diterima == null && pb.selesai == 0" -->
                  <b-button
                  v-if="pb.diterima == null && pb.selesai == 0"
                    title="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>

            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </main>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BCard,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    Cleave,
    BForm,
    BCard,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(status) {
      if (status) {
        this.getData();
      }
    },
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      loading: false,
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_pajak: null,
        item: "",
        qty: 0,
        biaya: 0,
      },
      form2: {
        id_akun: null,
        id_kas: null,
        selesai : 1,
      },
      form3: {},
      form4: {
        selesai: 1,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "tanggal",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      id_kas: [],
      id_akun:[],
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id_biaya_pajak", label: "Kode", sortable: true },
        { key: "item", label: "item", sortable: true },
        { key: "qty", label: "qty" },
        { key: "biaya", label: "biaya" },
        { key: "jumlah", label: "jumlah" },
        { key: "actions", label: "Aksi" },
      ],
      
      items: [],
      pb: {
        tanggal: null,
        keterangan: null,
        total: null,
        diberikan: null,
        diterima: null,
        selesai: null,
        id_kas: null,
      },
      showModalapprfinance: false,
      formcancelakun:{
        id:null,
      },
    };
  },
  computed: {
    invalidAkun() {
      const akun = this.id_akun.find(item => item.id == this.form2.id_akun)
      if(!akun) return false

      return akun && (parseInt(akun.saldo) < 1 || parseInt(akun.saldo) < this.pb.total)
    },
    invalidKas() {
      const kas = this.id_kas.find(item => item.id == this.form2.id_kas)
      if(!kas) return false

      return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < this.pb.total)
    },
    isValidAkunForm() {
      if(!this.form2.id_akun || !this.form2.id_kas) {
        return false
      }

      // get selected akun and kas and validate saldo
      if(this.invalidKas) {
        return false
      }

      return true

    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsDetail
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    this.getData();
    this.getPb();
    // this.getStatus();
  },
  methods: {
    confirmcanceljurnal() {
      this.$swal({
        title: "Anda yakin?",
        text: "Cancel akun dan kas untuk biaya pajak sekarang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.canceljurnal();
          this.$swal({
            icon: 'success',
            title: 'Checkout',
            text: 'Anda telah cancel akun dan kas untuk biaya pajak ini',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Batal',
            text: 'Anda telah membatalkan cancel akun dan kas untuk biaya pajak',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    
    async canceljurnal() {
      if (this.$route.params.id) {
        this.formcancelakun.id = this.$route.params.id;
      }
      const payload = this.formcancelakun;
      try {
        const ajuan = await this.$store.dispatch("biaya_pajak/cancel", [payload]);
        if (this.id) {
          this.getData();
          this.getPb();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Biaya pajak sudah di cancel akun dan kas oleh finance",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    doFormatRupiah() {
      this.form.biaya = this.formatRupiah( this.form.biaya )
    },
    confirmselesai() {
      this.$swal({
        title: "Anda yakin?",
        text: "Mengajukan Rincian Biaya Pengambilang Barang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(() => {
        this.selesai();
      });
    },
    confirmditerima() {
      this.$swal({
        title: "Anda yakin?",
        text: "Sudah terima Biaya Pengambilang Barang",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(() => {
        this.diterima();
      });
    },
    confirmdiberikan() {
      this.showModalapprfinance = true;
      this.getKas();
      this.getakun();
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_akun = akun.filter(item => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(item.jenis));
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_kas = kas;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async diterima() {
      if(!this.isValidAkunForm) {
        this.displayError({
          message: 'Harap lengkapi form pemilihan akun dengan benar!'
        })
        return false
      }
      if (this.$route.params.id) {
        this.form3.id = this.$route.params.id;
      }
      const payload = this.form3;
      try {
        const ajuan = await this.$store.dispatch("biaya_pajak/terima", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Biaya pajak telah diterima",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async diberikan() {
      if (this.$route.params.id) {
        this.form2.id = this.$route.params.id;
      }
      const payload = this.form2;
      try {
        const ajuan = await this.$store.dispatch("biaya_pajak/jurnal", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.resetForm();
          this.displaySuccess({
            message: "Biaya pajak telah diberikan",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async selesai() {
      if (this.$route.params.id) {
        this.form4.id = this.$route.params.id;
      }
      const payload = this.form4;
      try {
        const ajuan = await this.$store.dispatch("biaya_pajak/save", [payload]);
        if (this.id) {
          this.getData();
        } else {
          this.getPb();
          this.displaySuccess({
            message: "Biaya pajak sudah diajukan ke finance",
          });
        }
      } catch (e) {
        console.error(e);
        this.displayError(e);
        return false;
      }
    },
    async getPb() {
      const pb = await this.$store.dispatch(
        "biaya_pajak/getDataById",
        this.$route.params.id
      );
      this.pb = pb;
    },
    async getBarangPb() {
      const barangs = await this.$store.dispatch("biaya_pajak/getData", {
        id_pajak: this.$route.params.id,
      });
      this.barangs = barangs;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      this.showModalapprfinance = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data rincian ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("biaya_pajak/save2", [item])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil dihapus",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
      this.showModalapprfinance = false;
    },
    submit() {
      this.$refs.formbiaya_pajak.validate().then(async (success) => {
        if (success) {
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.id_pajak = this.$route.params.id;
          this.form.biaya = this.unFormatRupiah(this.form.biaya)
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("biaya_pajak/save2", [
              payload,
            ]);
            this.label = "Submit";
            this.getData();

            this.displaySuccess({
              message: "Rincian biaya pajak telah disimpan",
            });
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getData() {
      // set payload if sales itself, filter per sales itself
      // let params = this.isAdminGudang ? { id_biaya_pajak: this.myGudang.id } : {};

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("biaya_pajak/getData2", {
        id_pajak: this.$route.params.id,
      });
      this.loading = false;
      this.items = ajuans;
      // this.items.biaya = formatRupiah(ajuans.biaya);
      this.totalRows = ajuans.length;
    },
    // getid_gudang() {
    //   this.$store
    //     .dispatch("karyawan/getData", { jabatan_id: 5 })
    //     .then(() => {
    //       let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
    //       karyawan.map((item) => {
    //         item.value = item.id;
    //         item.text = item.nama_lengkap;
    //       });

    //       this.id_gudang = karyawan;
    //     })
    //     .catch((e) => {
    //       this.displayError(e);
    //       return false;
    //     });
    // },
    // getsalesName(data) {
    //   return data.sales ? data.sales.nama_lengkap : "-";
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
